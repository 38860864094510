import React from 'react';

import './Navbar.css';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
        <h1 className="title">PDFVoiceTranslate</h1>
    </nav>
  );
};

export default Navbar;