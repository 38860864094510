import React, {useState, useRef, useEffect} from 'react';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';

import './AudioPlayer.css';

interface TextIndex {
  incrementTextIndex: () => void;
  decrementTextIndex: () => void;
  musicData: Blob | null;
  currLang: any;
  setLang: any;
  fileName: string;
}

const AudioPlayer: React.FC<TextIndex> = ({ incrementTextIndex, decrementTextIndex, musicData, currLang, setLang, fileName }) => {
  // Refs
  const isPlayingRef = useRef(false);
  const hasAddedEventListener = useRef(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // States
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedSpeed, setSelectedSpeed] = React.useState(0.85);

  useEffect(() => {
    // Check there is an audio player and add eventlistener once
    if (audioRef.current && !hasAddedEventListener.current) {
      // Increment to next text passage once audio has ended
      audioRef.current.addEventListener('ended', () => {
        incrementTextIndex();
      });

      // Boolean check used to only run addEventListener once
      hasAddedEventListener.current = true;
    }    
  }, [audioRef]);

  useEffect(() => {
    // When the file changes stop everything
    const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement
    audioPlayer.pause()

    isPlayingRef.current = false
    setIsPlaying(false);
  }, [fileName])

  const handleBackward = () => {
    // Decrement to previous text passage
    if (audioRef.current) {
      isPlayingRef.current = true;
      setIsPlaying(true);
      decrementTextIndex();
    }
  };

  const handleForward = () => {
    // Increment to next text passage
    if (audioRef.current) {
      isPlayingRef.current = true;
      setIsPlaying(true);
      incrementTextIndex();
    }
  };

  const playButton = () => {
    // Get the audio player element
    const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement

    if (isPlaying) {
      // If audio is currently playing stop the audio
      isPlayingRef.current = false
      setIsPlaying(false);
      audioPlayer.pause()
    } else {
      // If audio is currently not playing start the audio from the beginning
      isPlayingRef.current = true
      setIsPlaying(true);

      if(audioPlayer.src){
        audioPlayer.currentTime = 0
        audioPlayer.play()
      }else{
        // Triggered during the first play
        incrementTextIndex();
      }
    }
  }

  useEffect(() => {
    // Play the audio when there is audio data
    if(musicData){
      const audioUrl = URL.createObjectURL(musicData);
      const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement
      audioPlayer.src = audioUrl;
    
      audioPlayer.addEventListener('canplaythrough', () => {
        audioPlayer.playbackRate = selectedSpeed;
        audioPlayer.play()
      })
    }
  }, [musicData])

  const handleSpeedChange = (event: any) => {
    // Change audio playback speed
    setSelectedSpeed(event.target.value);

    if (audioRef.current) {
      audioRef.current.playbackRate = event.target.value;
    }
  };

  const handleLanguageChange = (event: any) => {
    // Change target language
    setLang(event.target.value);
  };

  return (
    <div>
      <div className='playlist'>
        <div>
          <Select
              id="select"
              value={currLang}
              label="Language"
              onChange={handleLanguageChange}
            >
            <MenuItem value='vi'>Vietnamese</MenuItem>
            <MenuItem value='ko'>Korean</MenuItem>
            <MenuItem value='ja'>Japanese</MenuItem>
            <MenuItem value='zh-CN'>Mandarin</MenuItem>
            <MenuItem value='es'>Spanish</MenuItem>
            <MenuItem value='fr'>French</MenuItem>
            <MenuItem value='de'>German</MenuItem>

          </Select>

          <Button startIcon={<FastRewindIcon />} onClick={handleBackward}></Button>
          <Button startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />} onClick={playButton}></Button>
          <Button startIcon={<FastForwardIcon />} onClick={handleForward}></Button>

          <Select
            id="select"
            value={selectedSpeed}
            label="Playback Speed"
            onChange={handleSpeedChange}
          >
            <MenuItem value={0.5}>0.5x Speed</MenuItem>
            <MenuItem value={0.75}>0.75x Speed</MenuItem>
            <MenuItem value={0.85}>0.85x Speed</MenuItem>
            <MenuItem value={1}>1x Speed</MenuItem>
            <MenuItem value={1.25}>1.25x Speed</MenuItem>
          </Select>

        </div>
      </div>
      <audio id="audioPlayer" ref={audioRef} controls>
      </audio>
    </div>
  );
};

export default AudioPlayer;