import React, {useState, FormEvent, ChangeEvent} from 'react';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

import './Uploader.css';

interface UploaderProps {
  setFileDataUrl: (dataUrl: string | null) => void;
  setFileName: (any);
}

const Uploader: React.FC<UploaderProps> = ({ setFileDataUrl, setFileName }) => {
  // States
  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = (event: FormEvent) => {
    // Prevent form submission
    event.preventDefault();

    // Check file is selected
    if (!file) {
      console.error('No file selected');
      return;
    }

    // Set file name upon submission
    setFileName(file.name)

    // Read file and set the contents of file
    const reader = new FileReader();
    reader.onload = (e: any) => {
      // Set the file content as a Data URL
      const resultString: string = e.target.result as string;
      setFileDataUrl(resultString);
    }

    // Read the file as a Data URL
    reader.readAsDataURL(file);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput: FileList | null = event.target.files;
    const file: File | null =  fileInput?.[0] ?? null;

    if (!file || file.type !== 'application/pdf') {
      alert('Please select a PDF file.');
      event.target.value = "";
    }

    setFile(file);
  }

  return (
      <form onSubmit={handleSubmit}>
        <Input
        type="file"
        inputProps={{ accept: 'application/pdf' }}
        id="fileInput"
        color="secondary"
        margin="dense"
        onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary" id="uploadButton">Upload</Button>
      </form>
  );
};

export default Uploader;