import {useState, useRef, useEffect} from 'react';
import { Analytics } from '@vercel/analytics/react';
import Cookies from 'js-cookie';

import Navbar from './components/Navbar/Navbar';
import Uploader from './components/Uploader/Uploader';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import PdfViewer from './components/PdfViewer/PdfViewer';
import getDocumentText from './utilities/GetDocumentText';
import translate from './utilities/Request';

import './App.css';

function App() {
  // States
  var [fileName , setFileName]= useState<string>("");
  const [fileDataUrl, setFileDataUrl] = useState<string | null>(null);
  const [textIndex, setTextIndex] = useState<number>(-1);
  const [newTextFormatted, setNewTextFormatted] = useState<{[key: string]: any[]}>({});
  const [musicData, setMusicData] = useState<Blob | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState('vi');

  // Refs
  var page = useRef(Number(Cookies.get(fileName)) || 1);

  const API_URL: string = process.env.REACT_APP_API_URL? process.env.REACT_APP_API_URL: ''

  useEffect(() => {
    if(fileDataUrl){
      // Retrieve text from the page
      getDocumentText(fileDataUrl, page.current, setNewTextFormatted);
    }
  }, [fileDataUrl])

  useEffect(() => {
    if (Object.keys(newTextFormatted).length > 0) {
      // Convert text into specified language and get audio data
      const keys = Object.keys(newTextFormatted);
      translate(API_URL, keys[textIndex], selectedLanguage, setMusicData)

      if (textIndex > Object.keys(newTextFormatted).length - 5 && fileDataUrl) {
        // Preload text from the next page
        page.current += 1
        getDocumentText(fileDataUrl, page.current, setNewTextFormatted);    
      }
    }
  }, [textIndex])

  useEffect(() => {
    if (fileName !== "") {
      console.log("File name: " + fileName + " Page: " + Cookies.get(fileName))
      page.current = Number(Cookies.get(fileName)) || 1

      // Reset the text and also the highlight
      setNewTextFormatted({});
      setTextIndex(-1);


    }
  }, [fileName])

  const incrementTextIndex = () => {
    setTextIndex((prevIndex: number) => prevIndex + 1);
  };

  const decrementTextIndex = () => {
    setTextIndex((prevIndex: number) => prevIndex > 0? prevIndex - 1: prevIndex);
  };

  return (
    <div className="App">
      <Navbar />
        <p>Enhance Your Reading Experience: Text-to-Speech (TTS) for PDFs, Speak in Multiple Languages!</p>
        <Uploader setFileDataUrl={setFileDataUrl} setFileName={setFileName}/>
        {fileDataUrl &&
          <div>
            <AudioPlayer incrementTextIndex={incrementTextIndex} decrementTextIndex={decrementTextIndex} musicData={musicData} currLang={selectedLanguage} setLang={setSelectedLanguage} fileName={fileName}></AudioPlayer>
            <PdfViewer fileDataUrl={fileDataUrl} text={newTextFormatted} textIndex={textIndex} fileName={fileName}></PdfViewer>
          </div>
        }
        <Analytics />
    </div>
  );
}

export default App;
