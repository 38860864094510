import React, {useState, useRef, useEffect} from 'react';
import Cookies from 'js-cookie';
import type { IHighlight } from 'react-pdf-highlighter';
import {
    PdfHighlighter,
    Highlight,
    PdfLoader,
  } from 'react-pdf-highlighter';
import { v4 as uuidv4 } from 'uuid';

import './PdfViewer.css';

interface MyComponentProps {
  fileDataUrl: string,
  text: any,
  textIndex: number,
  fileName: string
}

const PdfViewer: React.FC<MyComponentProps> = ({ fileDataUrl, text, textIndex, fileName }) => {
  // Refs
  const pdfHighlighterRef = useRef<any>(null);

  // States
  const [highlights, setHighlights] = useState<IHighlight[]>([]);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (textIndex >= 0) {
      var keys = Object.keys(text)

      // Highlight text
      handleAddHighlight(text[keys[textIndex]]);

      // Set cookie based on current highlight page number
      if(highlights.length > 0 && highlights[0].position.boundingRect.pageNumber != undefined){
        Cookies.set(fileName, highlights[0].position.boundingRect.pageNumber?.toString(), { expires: 30 });
      }
    }
  }, [textIndex])

  useEffect(() => {
    setHighlights([])
  }, [fileName])

  useEffect(() => {
    if (pdfHighlighterRef.current && highlights.length > 0) {
      const highlightId = highlights[0].id;
      const highlightElement = document.getElementById(highlightId);
      
      if (highlightElement) {
        highlightElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  })

  useEffect(() => {
    if (pdfHighlighterRef.current) {
      // Get page Width and Height for later calculations
      const pw = pdfHighlighterRef.current.viewer.getPageView(0)
      const trackedElement = document.getElementsByClassName('PdfHighlighter')[0] as HTMLElement;

      if (trackedElement) {
        trackedElement.style.height = pw.height + 'px';
      }
      setWidth(pw.width)
      setHeight(pw.height)
    }
  }, [pdfHighlighterRef.current]);

  const getHighlightPositions = (item: Array<any>): any[] => {
    // Scale according to normal screen dpi
    const scale = (96 / 72);

    // List of highlights for a sentence
    const scaledPositions: any[] = [];

    item.forEach((element:any) => {
      const scaledPosition: any = {
        "x1": element.transform[4]  * scale,
        "y1": height as number - ((element.transform[5] + element.height) * scale) as number,
        "x2": (element.transform[4] + element.width) * scale ,
        "y2": height as number - (element.transform[5] * scale),
        "pageNumber": element.page,
        "width":width,
        "height":height
      };
      
      scaledPositions.push(scaledPosition);
    });

    return scaledPositions
  }

  const handleAddHighlight = (item: Array<any>) => {
    const scaledPositions: any[] = getHighlightPositions(item);
  
        const newHighlight: IHighlight = {
      id: uuidv4(),
      content: {
        text: (item as any).str, // Highlighted text
      },
      position: {
        boundingRect: scaledPositions[0],
        rects: scaledPositions, // Positions of highlights for the text
        pageNumber: 0, // Default page number
      },
      comment: {
        text: '',
        emoji: '',
      },
    };

    // Set new highlights
    setHighlights([newHighlight]);
  };

  return (
    <PdfLoader url={fileDataUrl} beforeLoad={<div>Loading...</div>} >
    {(pdfDocument) => (
      <div>
        <PdfHighlighter
          pdfDocument={pdfDocument}
          ref={pdfHighlighterRef}
          scrollRef={scrollTo => {}}
          highlights={highlights}
          enableAreaSelection={(event) => event.altKey}
          onScrollChange={() => {document.location.hash = "";}}
          onSelectionFinished={(
            position,
            content,
            hideTipAndSelection,
            transformSelection
          ) => {
            // Automatically add a highlight for the selected text
            console.log("On selection finished", content, position);
            return null
          }}
          pdfScaleValue='1'
          highlightTransform={(
            highlight,
            index,
            setTip,
            hideTip,
            viewportToScaled,
            screenshot,
            isScrolledTo
          ) => {
            const isTextHighlight = !Boolean(
              highlight.content && highlight.content.image
            );
              
            const component = isTextHighlight ? (
                  <Highlight
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                />
            ): null

            return (
                <div id={highlights[0].id}>
                  {component}
                </div>
              );
          }}
        />
      </div>
    )}
  </PdfLoader>
  );
};

export default PdfViewer;