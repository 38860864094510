const translate = async (API_URL: string, text: string, langugage: string, setMusicData: any) => {
    if (API_URL === '') {
        console.log('API url environment not set')
    }

    fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: text, lang: langugage })
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob()
    })
    .then((responseData) => {
      setMusicData(responseData)
    })
    .catch((error) => {
      // Handle any errors
      alert("Limit Exceeded For The Day")
      console.error('Error:', error);
    });
}

  export default translate;